import React from "react";
import {
  SliderInput,
  SliderTrack,
  SliderTrackHighlight,
  SliderHandle,
  SliderMarker,
} from "@reach/slider";
import {Box, XCol} from "./xui/Box";
import {sliderMarkerStyle} from "./PricingSlider.css";

const Mark = ({children, active}) => (
  <XCol align="center" absolute bottom="100%" width="2.5" pb={2} style={{left: "-1.1rem"}}>
    <Box bold size="xs" color={active ? "purple600" : "hint"}>
      {children}
    </Box>
  </XCol>
);

const Marker = props => (
  <Box
    as={SliderMarker}
    rounded="full"
    width="0.5"
    height="0.5"
    borderWidth="1"
    borderColor="imgBorder"
    top="50%"
    style={{transform: "translate(-50%, -50%)"}}
    className={sliderMarkerStyle}
    {...props}
  />
);

const PricingSlider = ({value, onChange, max}) => (
  <Box
    as={SliderInput}
    min={0}
    max={max}
    value={value}
    step={1}
    onChange={onChange}
    height="0.5"
    width="100%"
  >
    <Box as={SliderTrack} rounded="full" borderWidth="1" borderColor="imgBorder" height="100%">
      <Marker value={4}>
        <Mark active={value === 4}>€250</Mark>
      </Marker>
      <Marker value={14}>
        <Mark active={value === 14}>€750</Mark>
      </Marker>
      <Marker value={26}>
        <Mark active={value === 26}>€2,000</Mark>
      </Marker>
      <Box as={SliderTrackHighlight} bg="themeAccent" rounded="full" />
      <Box
        as={SliderHandle}
        buttonFocus
        width="1"
        height="1"
        rounded="full"
        borderWidth="1"
        borderColor="purple700"
        top="50%"
        bg="bgBack"
        style={{transform: "translateY(-50%)"}}
      />
    </Box>
  </Box>
);

export default PricingSlider;
