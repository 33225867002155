import React, {useState} from "react";
import xcolors from "../lib/xcolors";
import {TransparentButton} from "./Button";
import Icon from "./Icon";
import {ArrowOverlay, SpawnAnchoredOverlay} from "./Overlay";
import Col from "./ui/Col";
import {Text} from "./ui/Text";

const TooltipOverlay = ({overlayProps, tooltip}) => (
  <ArrowOverlay
    background={xcolors.gray800}
    color={xcolors.white}
    contentCss={{width: "15rem", padding: "1rem 1.5rem"}}
    arrowSize={5}
    {...overlayProps}
  >
    <Col sp={2}>
      <Text size={2} color="white">
        {tooltip}
      </Text>
    </Col>
  </ArrowOverlay>
);

const InfoTooltip = ({tooltip}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <SpawnAnchoredOverlay
      distanceFromAnchor={15}
      placement="right"
      isOpen={open}
      renderOverlay={p => <TooltipOverlay overlayProps={p} tooltip={tooltip} />}
    >
      {measureRef => (
        <TransparentButton
          size="small"
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          onClick={() => setOpen(!open)}
          ref={measureRef}
          color="dim"
        >
          <Icon.Question />
        </TransparentButton>
      )}
    </SpawnAnchoredOverlay>
  );
};

export default InfoTooltip;
